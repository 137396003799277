/* Add your CSS for other elements */

/* Media query for laptops */
@media only screen and (max-width: 1024px) {
    .slick-slide {
      width: 50%; /* Show 2 slides per row */
    }
  }
  
  /* Media query for phones */
  @media only screen and (max-width: 768px) {
    .slick-slide {
      width: 100%; /* Show 1 slide per row */
    }
  }
  