.faq-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-list {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .faq-item {
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
  }
  
  .faq-question {
    padding: 12px;
    background-color: #f3f3f3;
    font-weight: bold;
  }
  
  .faq-question.active {
    background-color: #eaeaea;
  }
  
  .faq-answer {
    padding: 12px;
  }
  
  h1 {
    text-align: center;
  }
  
  