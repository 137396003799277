/* OurMission.css */

.our-mission {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .mission-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mission-item {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
    max-width: 400px;
  }
  
  .mission-item h2 {
    font-size: 1.5rem;
  }
  
  .mission-image {
    margin: 2rem 0;
    max-width: 80%;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* Add image styling here */
  }
  