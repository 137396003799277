.App{
background-color: black;
color: #ffc554;
}
.message{
display: inline-block;
position: relative;

background-color: #198754;
padding-left: 20px;
padding-right: 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#fade{
  box-shadow: 8px 8px black inset;
}

Explain
/* @import url('//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-theme.min.css'); */


ule {
    list-style-type: disc !important;
    padding-left:1em !important;
    margin-left:1em;
}