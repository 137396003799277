#el {
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    color: #cb9d43;
    background-color: #e3f2fd;
  }

.nav-link{
  color: #cb9d43;

  }


  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  