.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
  }
  
  h1 {
    font-size: 24px;
    margin: 20px 0;
    color: #333;
  }
  
  .manager-details,
  .contact-links {
    padding: 20px;
  }
  
  .manager-details h2,
  .contact-links h2 {
    font-size: 20px;
    margin: 10px 0;
    color: #333;
  }
  
  p {
    font-size: 16px;
    margin: 10px 0;
    color: #777;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
  }
  
  a {
    text-decoration: none;
    color: #007BFF;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
  }
  
  a:hover {
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .manager-details,
    .contact-links {
      padding: 10px;
    }
  
    h1 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  
    ul {
      margin-left: 0;
      padding-left: 0;
    }
  
    a {
      font-size: 16px;
    }
  }
  