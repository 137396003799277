.footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    color: white;
    flex-wrap: wrap;
    min-height: 150px;
}

.b1footer{
    text-align: left;
    background-color: black;
    color: white;
    flex-wrap: wrap;
    min-height: 30px;
    padding: 20px;

}

.colord{
    text-decoration: none;
    text-align: initial;
}


@media screen and (min-width:700px) {
    .ces{
        display: none;
    }
}