.movein{
animation: 2s ease into;

}
@keyframes into {
    from{
        transform: translateX(-20vw);
        
    }
    to{
        transform: translateX(0vh);
    }
    
}



.image-container {
    position: relative;
  }
  
  
  .image-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, rgb(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    animation: shine 3s linear infinite;
  }

  @keyframes shine {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    51% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
  }
  