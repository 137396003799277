.Xpp {
    text-align: center;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../Images/bulding.png');
    background-size: cover;
    background-position: center;
    clip-path: url('../Images/img.svg');
   }
   
   .container2 {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    position: relative;
    max-width: 70%;
    right:2rem;
    animation: 2s ease-out inpage ;

   }
   
   .e21 {
    margin-bottom: 15px;
    position: relative;
   }
   .b3{
    color: black;
   }
   .container2:hover{
    background-color: black;
    .b3{
        color: white;
       }
   }
   
   
   /* p {
    margin-bottom: 30px;
   }
    */
   button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
   }


   @keyframes inpage {
    from{
        transform: translateY(20vh);
    }to{
        transform: translateY(0);
    }
    
   }



   